@font-face {
  font-family: 'MuseoSansCyrl';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/MuseoSansCyrl-300.eot');
  src: local(''), url('/fonts/MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
    url('/fonts/MuseoSansCyrl-300.woff') format('woff'), url('/fonts/MuseoSansCyrl-300.ttf') format('truetype'),
    url('/fonts/MuseoSansCyrl-300.svg') format('svg');
}

@font-face {
  font-family: 'MuseoSansCyrl';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/MuseoSansCyrl-500.eot');
  src: local(''), url('/fonts/MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
    url('/fonts/MuseoSansCyrl-500.woff') format('woff'), url('/fonts/MuseoSansCyrl-500.ttf') format('truetype'),
    url('/fonts/MuseoSansCyrl-500.svg') format('svg');
}

@font-face {
  font-family: 'MuseoSansCyrl';
  font-style: normal;
  font-weight: bold;
  src: url('/fonts/MuseoSansCyrl-700.eot');
  src: local(''), url('/fonts/MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
    url('/fonts/MuseoSansCyrl-700.woff') format('woff'), url('/fonts/MuseoSansCyrl-700.ttf') format('truetype'),
    url('/fonts/MuseoSansCyrl-700.svg') format('svg');
}

:root {
  --font-regular: 'MuseoSansCyrl', sans-serif;
}
