:root {
  --transparent: rgba(255, 255, 255, 0);
  --white: #fff;

  --black: #000;
  --black2: #111111;
  --text: #232832;
  --paleText: #333333;

  --gray: #79818c;
  --gray2: #b1b1b1;
  --gray3: #c1c7cd;
  --gray4: #efefef;
  --gray5: #eaeaea;
  --gray6: #f4f4f5;
  --gray7: #f7f6ff;
  --gray8: #f7f7f7;
  --gray9: #93969d;
  --gray10: #f5f6fa;

  --red: #ff3366;
  --red2: #e53032;
  --red3: #e9003a;

  --purple: #8b33ff;
  --purple2: #771cef;
  --purple3: #51018e;
  --purple4: #ebd4ff;

  --green: #24af1e;
  --green2: #01b788;
  --green3: #00c54b;

  --yellow: #ffc910;
  --gold: #ffbb00;

  --gradientPrimary: linear-gradient(to right, #8934ff 5%, #993dfa 54%, #ae4cfe);
  --gradient2: linear-gradient(to right, #8c30db 5%, #9531eb 48%, #6f1fce);

  --pattensBlue: #e5f4ff;
  --titanWhite: #f0edfb;
  --linen: #ffede5;
  --halfDutchWhite: #fcf8e0;
  --aquaSpring: #eff8f7;
  --whiteLilac: #f7f6ff;
}
